<template>
  <div>
    <b-row>
      <b-col md="8" class="mx-auto">
        <h3 class="mb-2">{{ $t("dashboard.rewardProgram") }}</h3>
        <b-row>
          <b-col md="3">
            <b-card :title="$t('rewards.pendingPoints')">
              <h3>{{ user.pendingPoints }}</h3>
            </b-card>
          </b-col>
          <b-col md="3">
            <b-card :title="$t('rewards.earnedPoints')">
              <h3>{{ user.points }}</h3>
            </b-card>
          </b-col>
          <b-col md="3">
            <b-card :title="$t('rewards.redeemedPoints')">
              <h3>{{ user.redeemPoints }}</h3>
            </b-card>
          </b-col>
          <b-col md="3">
            <b-card :title="$t('rewards.pointsBalance')">
              <h3>{{ user.pendingPoints + user.points }}</h3>
            </b-card>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="12">
            <b-alert class="p-2" variant="danger" show>{{
              $t("rewards.alert")
            }}</b-alert>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <b-card :title="$t('rewards.yourRefferalLink')">
              <b-form-input
                class="mb-1"
                disabled
                :value="`https://aiclub.ae/register/${$store.getters['auth/user']._id}`"
              />
              <b-link>{{ $t("rewards.copyLink") }}</b-link>
            </b-card>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="6">
            <b-card no-body>
              <b-card-header
                ><strong>{{ $t("rewards.pointsTable") }}</strong></b-card-header
              >
              <b-table
                :empty-text="
                  isLoadingPoints
                    ? $t('rewards.pleaseWait')
                    : $t('rewards.noData')
                "
                :fields="pointsColumns"
                :items="points"
                :show-empty="true"
              >
                <template #cell(title)="data">
                  {{
                    $i18n.locale == "en"
                      ? data.item.title
                      : data.item.titleArabic
                  }}
                </template>
                <template #cell(points)="data">
                  {{ data.item.amount }}
                </template>
              </b-table>
            </b-card>

            <b-card no-body>
              <b-card-header>{{ $t("rewards.rewards") }}</b-card-header>
              <b-table :items="rewards"></b-table>
            </b-card>
          </b-col>
          <b-col md="6">
            <b-card :title="$t('rewards.transferMessage')">
              <div class="text-center mb-2">
                <img src="/qr.png" alt="" width="200" />
              </div>
              <div>
                <h3>{{ $t("rewards.walletAddress") }}:<br /></h3>
                <input
                  class="form-control mb-1"
                  type="text"
                  value="TE2dskqy1o3fgrTPRknhitnUEY6m6Y8jWT"
                  disabled
                />
                <b-link
                  :to="{ name: 'RewardsTransfer' }"
                  variant="primary"
                  block
                  size="lg"
                  >{{ $t("rewards.clickHereWhenYouTransferred") }}</b-link
                >
              </div>
            </b-card>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import store from "@/store";

import {
  BRow,
  BCol,
  BCard,
  BFormInput,
  BCardHeader,
  BTable,
  BLink,
  BAlert,
  BButton,
} from "bootstrap-vue";
import axios from "axios";
export default {
  name: "Rewards",
  components: {
    BRow,
    BCol,
    BCard,
    BFormInput,
    BCardHeader,
    BTable,
    BLink,
    BAlert,
    BButton,
  },
  data() {
    return {
      isLoadingPoints: false,
      user: null,
      points: [],
      rewards: [
        { points: 5000, reward: "$55" },
        { points: 7500, reward: "$80" },
        { points: 10000, reward: "$120" },
        { points: 12500, reward: "$140" },
        { points: 15000, reward: "$165" },
        { points: 20000, reward: "$210" },
        { points: 25000, reward: "$265" },
        { points: 30000, reward: "$315" },
        { points: 35000, reward: "$364" },
        { points: 40000, reward: "$420" },
        { points: 1000000, reward: "iPhone 14 Pro Max" },
      ],
      pointsColumns: [
        {
          key: "title",
          label: this.$t("rewards.activity"),
        },
        {
          key: "points",
          label: this.$t("rewards.points"),
        },
      ],
    };
  },
  methods: {
    loadPoints: async function () {
      this.isLoadingPoints = true;
      const { data } = await axios.get("/points");
      this.points = data.data;
      this.isLoadingPoints = false;
    },
  },
  mounted() {
    this.loadPoints();
  },
  created() {
    store.commit("appConfig/UPDATE_NAV_MENU_HIDDEN", false);
    store.commit("appConfig/UPDATE_FOOTER_CONFIG", { type: "static" });
    store.commit("appConfig/UPDATE_NAVBAR_CONFIG", { type: "sticky" });
    this.user = store.getters["auth/user"];
  },
};
</script>
